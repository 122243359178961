export const experience_list = [
  {
    beginYear: 2020,
    endYear: 2022,
    title:
      "PENGADAAN DAN PENAMBAHAN STREAM GAS CUSTODY METER DI SPG PAGARDEWA PRABUMULIH FIELD PT PERTAMINA EP ASSET 2",
  },
  {
    beginYear: 2018,
    endYear: 2019,
    title:
      "PENGADAAN DAN PEMASANGAN METERING SYSTEM DI MAIN GATHERING STATION PT. PERTAMINA EP ASSET 1 PANGKALAN SUSU FIELD",
  },
  {
    beginYear: 2018,
    endYear: 2019,
    title:
      "PENGADAAN 2 (DUA) UNIT GAS CHROMATOGRAPHY C/W ACESSORIES METERING SALES PENDOPO FIELD",
  },
  {
    beginYear: 2017,
    endYear: 0,
    title:
      "RETROFIT SISTEM KONTROL SAT 250 PLTA UNIT 1 DAN 2 PLTA RENUN PT PLN (PERSERO) PEMBANGKITAN SUMETARA",
  },
  {
    beginYear: 2014,
    endYear: 2016,
    title:
      "PENYEWAAN 1 (SATU) UNIT DEHYDRATION UNIT LENGKAP DENGAN TENAGA KERJA UNTUK OPERASIONAL PRODUKSI",
  },
  {
    beginYear: 2014,
    endYear: 2015,
    title:
      "PENGADAAN DAN PEMASANGAN METER TUBE CUSTODY METERING GAS DI SP BETUNG, SKG 8 MUSI TIMUR DAN SKG 18 B (BIDANG : PERAWATAN DAN PERBAIKAN ALAT)",
  },
  {
    beginYear: 2014,
    endYear: 2015,
    title:
      "PENGADAAN DAN PEMASANGAN METER TUBE CUSTODY METERING GAS DI SP BETUNG, SKG 8 MUSI TIMUR DAN SKG 18 (BIDANG : INDUSTRI PERALATAN)",
  },
  {
    beginYear: 2012,
    endYear: 2013,
    title:
      "PENGADAAN DAN PEMASANGAN 2 (DUA) UNIT ORIFICE FLOWMETER BASED CUSTODY TRANSFER METER",
  },
  {
    beginYear: 2012,
    endYear: 0,
    title:
      "PUMP STANDARD ACCORDING TO API-610 TYPE : CENTRIFUGAL (MULTITEC RO A 65)",
  },
  {
    beginYear: 2011,
    endYear: 2014,
    title:
      "PENYEDIAAN KENDARAAN RINGAN PENUMPANG (KRP) BARU LENGKAP DENGAN PENGEMUDINYA SERTA SEPEDA MOTOR BARU",
  },
  {
    beginYear: 2011,
    endYear: 2012,
    title: "WELL HEAD COMPRESSOR",
  },
];
