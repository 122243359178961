import React, { useEffect, useState } from "react";
import Hamburger from "./Hamburger";
import "./style.css";

const Navbar = () => {
  const [nav, openNav] = useState(false);
  const [mobile, setMobile] = useState(
    window.matchMedia("(max-width: 992px)").matches
  );

  const showItem = nav ? "active" : "";
  const [offset, setOffset] = useState(0);
  const navbarColor =
    offset > 0 ? "white" : mobile ? "white static" : "transparent";
  const navbarShadow = offset > 0 && "navbar-shadow";

  useEffect(() => {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMobile(e.matches));

    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const handleNavbar = () => {
    openNav(!nav);
  };

  return (
    <div className={`navbar bg-${navbarColor} ${navbarShadow}`}>
      <span className="navbar-icon">
        <img src="images/logo2.png" alt="logo" />
        {mobile && (
          <Hamburger offset={offset} close={nav} onClick={handleNavbar} />
        )}
      </span>
      <ul className={`navbar-item-group ${showItem}`}>
        <li className="navbar-item">
          <a onClick={handleNavbar} href="#home">
            Home
          </a>
        </li>
        <li className="navbar-item">
          <a onClick={handleNavbar} href="#about">
            About
          </a>
        </li>
        <li className="navbar-item">
          <a onClick={handleNavbar} href="#dokumentasi">
            Documentation
          </a>
        </li>
        <li className="navbar-item">
          <a onClick={handleNavbar} href="#experience">
            Experience
          </a>
        </li>
        <li className="navbar-item">
          <a onClick={handleNavbar} href="#qualification">
            Qualification
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
