import React from "react";
import "./style.css";
import Fade from "react-reveal/Fade";

const Home = () => {
  return (
    <section id="home" className="content">
      <div className="container">
        <div className="home-text">
          <Fade bottom>
            <h1>NRPS</h1>
            <p>
              PT. NIDA ROKAN PUTRI SULUNG adalah sebuah perusahaan yang bergerak
              dalam bidang usaha Leveransir Umum dan Kontraktor. Kami
              menyediakan layanan seperti Gas Metering, Kalibrasi, Upgrading,
              Instrumentasi, Jasa-Jasa pemasangan atau pemindahan material
              lainnya, dan pengadaan material
            </p>
          </Fade>
        </div>
        <Fade bottom>
          <a className="find-us" href="#footer">
            Find Us!
          </a>
        </Fade>
        <div className="overlay"></div>
      </div>
    </section>
  );
};

export default Home;
