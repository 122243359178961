const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^[a-z0-9!#$%&'*+\=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gm;

const PHONE_REGEX = /^[0-9]{0,13}$/gm;

const validateWithRegex = (string, regex) => {
  return String(string).toLowerCase().match(regex);
};

export const validateForm = (name, email, phone, message, setErrMsg) => {
  let status = false;
  let err_name = "";
  let err_email = "";
  let err_phone = "";
  let err_message = "";

  if (name.length < 3) {
    err_name = "Please fill your name (minimal 3 characters)";
  }

  if (email.length === 0) {
    err_email = "Please fill your email";
  } else if (!validateWithRegex(email, EMAIL_REGEX)) {
    err_email = "Please fill email with correct form (example123@example.com)";
  }

  if (phone.length < 10) {
    err_phone =
      "Please fill your phone with minimum length 10 and maximum length 13";
  } else if (!validateWithRegex(phone, PHONE_REGEX)) {
    err_phone = "Please fill phone with maximum 13 characters";
  }
  if (message.length < 20) {
    err_message = "Please fill your message (minimal 20 characters)";
  }

  if (
    err_name.length === 0 &&
    err_email.length === 0 &&
    err_phone.length === 0 &&
    err_message.length === 0
  ) {
    status = true;
  }

  setErrMsg({
    name: err_name,
    email: err_email,
    phone: err_phone,
    message: err_message,
  });

  return status;
};
