import React from "react";
import Navbar from "../components/common/Navbar";
import About from "../components/sections/About";
import ContactUs from "../components/sections/ContactUs";
import Experience from "../components/sections/Experience";
import Footer from "../components/sections/Footer";
import Home from "../components/sections/Home";
import Qualification from "../components/sections/Qualification";
import Servis from "../components/sections/Servis";

const Main = () => {
  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Servis />
      <Experience />
      <Qualification />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Main;
