import "./style.css";
import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useEffect } from "react";
import { validateForm } from "./form_utils";
import Fade from "react-reveal/Fade";

const ContactUs = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [emailSent, setEmailSent] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [err_msg, setErrMsg] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  useEffect(() => {
    if (formValid) {
      setEmailSent(null);

      emailjs
        .sendForm(
          process.env.REACT_APP_EMAIL_JS_SERVICE,
          process.env.REACT_APP_EMAIL_JS_TEMPLATE,
          form.current,
          process.env.REACT_APP_EMAIL_JS_API_KEY
        )
        .then(
          (result) => {
            setLoading(false);
            setEmailSent(true);
          },
          (error) => {
            setLoading(false);
            setEmailSent(false);
          }
        )
        .finally(() => {
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
          setFormValid(false);
        });
    }
  }, [formValid]);

  const sendEmail = (e) => {
    e.preventDefault();
    setEmailSent(null);
    if (validateForm(name, email, phone, message, setErrMsg)) {
      setFormValid(true);
      setLoading(true);
    }
  };

  return (
    <section id="contactUs" className="contact-us">
      <Fade bottom>
        <h1 className="title">Send Us Message Now</h1>
      </Fade>
      {emailSent === true && (
        <div className="success_form">
          Thank you for contacting us! We will be in touch with you soon.
        </div>
      )}
      {emailSent === false && (
        <div className="error_form">
          There was an error trying to send your message. Please try again.
        </div>
      )}

      <form ref={form} onSubmit={sendEmail} className="contactUs-form">
        <span className="contactUs-form-item">
          <label htmlFor="guest_name">Name</label>
          <input
            placeholder="Name"
            type="text"
            name="sender_name"
            id="guest_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="err_message">{err_msg["name"]}</div>
        </span>
        <span className="contactUs-form-item">
          <label htmlFor="guest_email">Email</label>
          <input
            placeholder="Email"
            type="email"
            name="sender_email"
            id="guest_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="err_message">{err_msg["email"]}</div>
        </span>
        <span className="contactUs-form-item">
          <label htmlFor="guest_phone">Phone Number</label>
          <input
            placeholder="Phone Number"
            type="phone"
            name="phone_num"
            id="guest_phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <div className="err_message">{err_msg["phone"]}</div>
        </span>
        <span className="contactUs-form-item">
          <label htmlFor="guest_message">Message</label>
          <textarea
            name="message"
            id="guest_message"
            cols="30"
            rows="10"
            placeholder="Your Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <div className="err_message">{err_msg["message"]}</div>
        </span>
        {loading && (
          <div className="loading">Please wait, message is being sent</div>
        )}
        <button
          type="submit"
          disabled={formValid}
          className={`submit-button ${formValid ? "disabled" : ""}`}
        >
          Send
        </button>
      </form>
    </section>
  );
};

export default ContactUs;
