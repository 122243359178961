import React from "react";
import "./style.css";
import Fade from "react-reveal/Fade";

const About = () => {
  return (
    <section id="about" className="about">
      <Fade bottom>
        <h1 className="title">VISI DAN MISI KAMI</h1>
        <div className="about-item  ">
          <p className="item-title ">Visi</p>
          <p className="item-description">
            Menjadi perusahaan/mitra kerja yang terpercaya, berkompeten, dan
            memberikan pelayanan yang terbaik kepada klien.
          </p>
        </div>
        <div className="about-item  ">
          <p className="item-title">Misi</p>
          <p className="item-description">
            Mengutamakan Pelayanan mutu dan Kepuasaan yang terbaik kepada klien.
          </p>
          <p className="item-description">
            Menciptakan dan membina hubungan kerja yang baik dengan melakukan
            Komunikasi yang efektif dan efisien.
          </p>
        </div>
      </Fade>
    </section>
  );
};

export default About;
