export const images_list = [
  {
    thumbnailURL: "images/dokumentasi/pic16.jpg",
    width: 1077,
    height: 620,
  },
  {
    thumbnailURL: "images/dokumentasi/pic15.jpg",
    width: 1280,
    height: 720,
  },
  {
    thumbnailURL: "images/dokumentasi/pic20.jpg",
    width: 1280,
    height: 720,
  },
  {
    thumbnailURL: "images/dokumentasi/pic21.jpg",
    width: 1280,
    height: 720,
  },
];

export const images_list_new = [
  {
    thumbnailURL: "images/dokumentasi/pic19.jpg",
    width: 1280,
    height: 730,
  },
  {
    thumbnailURL: "images/dokumentasi/pic17.jpg",
    width: 1280,
    height: 720,
  },
  {
    thumbnailURL: "images/dokumentasi/pic18.jpg",
    width: 1280,
    height: 720,
  },
  {
    thumbnailURL: "images/dokumentasi/pic22.jpg",
    width: 1280,
    height: 720,
  },
];
