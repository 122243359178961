import "./style.css";
import Fade from "react-reveal/Fade";

const Qualification = () => {
  return (
    <section id="qualification" className="qualification">
      <Fade bottom>
        <h1 className="title">Qualification</h1>
        <h1 className="qualification-description">
          We have registered for ISO 9001, 14001, 37001, 45001 certificate
        </h1>
        <div className="certificate-wrapper">
          <img
            src="images/dokumentasi/certificate1.jpg"
            alt="ISO 9001 : 2015"
          />
          <img
            src="images/dokumentasi/certificate2.jpg"
            alt="ISO 14001 : 2015"
          />
          <img
            src="images/dokumentasi/certificate3.jpg"
            alt="ISO 37001 : 2016"
          />
          <img
            src="images/dokumentasi/certificate4.jpg"
            alt="ISO 45001 : 2018"
          />
        </div>
      </Fade>
    </section>
  );
};

export default Qualification;
