import React from "react";
import "./style.css";
const Hamburger = ({ offset, close, onClick, mobile }) => {
  const hamburgerClass = close ? "hamburger-btn close" : "hamburger-btn";
  return (
    <div className={`${hamburgerClass} line-black`} onClick={onClick}>
      <div className="btn-line"></div>
      <div className="btn-line"></div>
      <div className="btn-line"></div>
    </div>
  );
};

export default Hamburger;
