import { Fade } from "react-reveal";
import "./style.css";
const ExperienceItem = ({ beginYear, endYear, title }) => {
  return (
    <Fade bottom>
      <div className="exp-card-wrapper">
        <div className="exp-time">
          <span>{beginYear}</span>
          {endYear !== 0 && (
            <>
              <span>-</span>
              <span>{endYear}</span>
            </>
          )}
        </div>
        <h1 className="exp-title">{title}</h1>
      </div>
    </Fade>
  );
};

export default ExperienceItem;
