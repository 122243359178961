import "./style.css";
import Fade from "react-reveal/Fade";

const Footer = () => {
  return (
    <div id="footer" className="footer">
      <div className="find-us-item">
        <div className="company-info">
          <Fade bottom>
            <div className="company-info-item">
              <h1 className="ci-item-title">Contact Us Now</h1>
              <div className="ci-item-group">
                <span className="ci-item">
                  <i className="fas fa-envelope"></i>
                  <span className="email-group">
                    <a href="mailto:nrps@nidarokan.co.id">
                      <p>nrps@nidarokan.co.id</p>
                    </a>
                    <a href="mailto:ediyanto@nidarokan.co.id">
                      <p>ediyanto@nidarokan.co.id</p>
                    </a>
                  </span>
                </span>
                <span className="ci-item">
                  <i className="fas fa-phone"></i>
                  <a href="tel:+6281260321794">
                    <p>0812-6032-1794</p>
                  </a>
                </span>
              </div>
            </div>
            <div className="company-info-item working-hours my-1">
              <h1 className="ci-item-title">Working Hours</h1>
              <div className="ci-item-group">
                <span className="ci-item">
                  <p>Monday - Friday</p>
                </span>
                <span className="ci-item">
                  <p>09:00 - 16:30</p>
                </span>
              </div>
            </div>
            <div className="company-info-item my-1">
              <h1 className="ci-item-title">Address</h1>
              <div className="ci-item-group">
                <span className="ci-item">
                  <p className="px">
                    Jl. Danau Singkarak No. 11 C, Medan - Sumatera Utara 20117
                  </p>
                </span>
              </div>
            </div>
          </Fade>
        </div>
        <Fade bottom>
          <div className="map-info">
            <iframe
              title="GoogleMap"
              frameBorder="0"
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}
        &q=Jl. Danau Singkarak No.11c, Sei Agul, Kec. Medan Bar., Kota Medan, Sumatera Utara 20117, Indonesia&zoom=15`}
              allowFullScreen
            ></iframe>
          </div>
        </Fade>
      </div>
      <div className="copyright">
        <Fade bottom>
          <p>&copy; Copyright 2022 Nidarokan</p>
          <p>Created by Edbert Yang</p>
        </Fade>
      </div>
    </div>
  );
};

export default Footer;
