import ExperienceItem from "../../common/ExperienceItem";
import { experience_list } from "./experience_data";
import "./style.css";
import Fade from "react-reveal/Fade";
const Experience = () => {
  return (
    <section id="experience" className="experience">
      <Fade bottom>
        <h1 className="title">Experience</h1>
      </Fade>
      <div className="exp-collection">
        {experience_list.map((item, key) => {
          return (
            <ExperienceItem
              key={key}
              beginYear={item.beginYear}
              endYear={item.endYear}
              title={item.title}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Experience;
