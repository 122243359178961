import React from "react";
import "./style.css";
import SimpleGallery from "./SimpleGallery";
import { images_list, images_list_new } from "./image_data";
import Fade from "react-reveal/Fade";

const Servis = () => {
  return (
    <section id="dokumentasi" className="service">
      <Fade bottom>
        <h1 className="title">Dokumentasi Kerja</h1>
        <SimpleGallery
          galleryID="nrps-work-documentation"
          images={images_list}
        />
        <div className="newest-project">
          <h1 className="title">Proyek Terbaru</h1>
          <p className="project-description">
            Pengadaan & Penambahan Stream Gas Custody Meter di SPG Pagardewa
            Prabumulih Field PT.Pertamina EP Asset 2
          </p>
          <SimpleGallery
            galleryID="nrps-work-documentation"
            images={images_list_new}
          />
        </div>
      </Fade>
    </section>
  );
};

export default Servis;
